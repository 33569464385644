<script>
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page_header_new.vue";
import appConfig from "@/app.config.json";

/**
 * Video component
 */
export default {
  page: {
    title: "Video",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "가이드",
      items: [
        {
          text: "UI Elements",
        },
        {
          text: "Video",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">상품관리 가이드</h4>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <!-- 16:9 aspect ratio -->
            <div class="ratio ratio-16x9">
              <iframe
                src="https://www.youtube.com/embed/tuwEV1cyg50"
                title="YouTube video"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <!-- end cardbody -->
        </div>
        <!-- end card -->
      </div>

      <div class="col-xl-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">전단행사 이미지관리 가이드</h4>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <!-- 16:9 aspect ratio -->
            <div class="ratio ratio-16x9">
              <iframe
                  src="https://www.youtube.com/embed/kCVYjYPVJhc"
                  title="YouTube video"
                  allowfullscreen
              ></iframe>
            </div>
          </div>
          <!-- end cardbody -->
        </div>
        <!-- end card -->
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">특매행사 순위관리 가이드</h4>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <!-- 16:9 aspect ratio -->
            <div class="ratio ratio-16x9">
              <iframe
                  src="https://www.youtube.com/embed/dZMCF0v2nF0"
                  title="YouTube video"
                  allowfullscreen
              ></iframe>
            </div>
          </div>
          <!-- end cardbody -->
        </div>
        <!-- end card -->
      </div>

      <div class="col-xl-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">베스트상품 순위관리 가이드</h4>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <!-- 16:9 aspect ratio -->
            <div class="ratio ratio-16x9">
              <iframe
                  src="https://www.youtube.com/embed/Ybk7lCW3KiM"
                  title="YouTube video"
                  allowfullscreen
              ></iframe>
            </div>
          </div>
          <!-- end cardbody -->
        </div>
        <!-- end card -->
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">마클톡 가이드</h4>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <!-- 16:9 aspect ratio -->
            <div class="ratio ratio-16x9">
              <iframe
                  src="https://www.youtube.com/embed/RoOU3NMbpCI"
                  title="YouTube video"
                  allowfullscreen
              ></iframe>
            </div>
          </div>
          <!-- end cardbody -->
        </div>
        <!-- end card -->
      </div>

      <div class="col-xl-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">고객의 소리, 푸시알림 가이드</h4>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <!-- 16:9 aspect ratio -->
            <div class="ratio ratio-16x9">
              <iframe
                  src="https://www.youtube.com/embed/02pRFyjiA3s"
                  title="YouTube video"
                  allowfullscreen
              ></iframe>
            </div>
          </div>
          <!-- end cardbody -->
        </div>
        <!-- end card -->
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">쿠폰 가이드</h4>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <!-- 16:9 aspect ratio -->
            <div class="ratio ratio-16x9">
              <iframe
                  src="https://www.youtube.com/embed/Sub9yrQIl-w"
                  title="YouTube video"
                  allowfullscreen
              ></iframe>
            </div>
          </div>
          <!-- end cardbody -->
        </div>
        <!-- end card -->
      </div>

      <div class="col-xl-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">MY정보 가이드</h4>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <!-- 16:9 aspect ratio -->
            <div class="ratio ratio-16x9">
              <iframe
                  src="https://www.youtube.com/embed/AY_PIiBkfiU"
                  title="YouTube video"
                  allowfullscreen
              ></iframe>
            </div>
          </div>
          <!-- end cardbody -->
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
